<template>
    <Button v-if="action === 'FreeVote'" 
            :class="['bg-tp-yellow p-3 rounded-xl flex-1 whitespace-nowrap', {'opacity-50 cursor-not-allowed': !availableFreeBoost}]" 
            @click="handleFreeVote">
        <p class="text-tp-black text-sm font-bold">{{ availableFreeBoost ? 'Voto gratis' : 'Voto gratis usado'}}</p>
    </Button>
    <Button v-if="action === 'Boost'" 
            class="bg-tp-yellow p-3 rounded-xl flex-1 whitespace-nowrap" 
            @click="emitBoost">
        <p class="text-tp-black text-sm font-bold">Añadir votos</p>
    </Button>
</template>

<script>
import rocket from '@/assets/images/rocket-icon.png'
import { toRefs } from 'vue';

export default {
    name: 'RankingAction',
    props: {
        action: String,
        availableFreeBoost: Boolean
    },
    setup(props, { emit }) {
        const { availableFreeBoost } = toRefs(props);

        console.log('availableFreeBoost', availableFreeBoost)

        const emitBoost = () => {
            emit('onClickBoost');
        }

        const handleFreeVote = () => {
            if (availableFreeBoost.value) {
                emit('onClickFreeVote');
            }
        };

        return {
        rocket,
        emitBoost,
        handleFreeVote
        }
    }
}
</script>
  