<template>
    <div class="flex flex-col gap-1 w-full border-2 bg-tp-black border-tp-yellow rounded-xl font-bold p-3">
        <div v-if="!alreadyHasSong" class="flex justify-center gap-1">
            <p class="text-sm">¡Toca sobre la canción que más te guste!</p>
        </div>
        <div v-if="!alreadyHasSong" class="flex items-center justify-center gap-1">
            <p class="text-sm">¿Prefieres añadir una nueva? ¡Toca aquí!</p>
            <img class="w-4 h-4" :src="arrow" alt="">
        </div>
        <div v-if="alreadyHasSong && mySongIsFirst" class="text-center">
            <p>¡Tu canción va ganando!</p>
        </div>
        <div v-if="alreadyHasSong && !mySongIsFirst" class="text-center">
            <p>¡Consigue los votos suficientes para poner tu canción en primera posición!</p>
        </div>
    </div>
</template>

<script>
import rocket from '@/assets/images/rocket-icon.png'
import arrow from '@/assets/images/arrow-down.png'

export default {
    name: 'BoostWarning',
    props: {
        alreadyHasSong: Boolean,
        mySongIsFirst: Boolean,
    },
    setup() {
        return {
            rocket,
            arrow
        }
    }
}
</script>